<template>
<div class="lesson-templates">
  <h1 class="title grey--text">录课</h1>
  <v-container class="my-5">
    <!-- <v-layout justify-center class="pa3 caption">
      <v-flex xs12 sm10 md8>
        <v-select
          v-model="programIds"
          :items="programs"
          item-text="title"
          item-value="program_id"
          :label="programs.length > 0? '所选项目': '暂无项目，请先创建项目'"
          small-chips
          multiple
          prepend-icon="ballot"
          menu-props="offsetY"
          @change="updateTemplates"
          :disabled="!(programs.length > 0)"
        ></v-select>
      </v-flex>
    </v-layout> -->

    <v-layout justify-center class="mb-2">
      <v-flex  class="d-flex justify-center">
        <v-btn
          class="primary"
          @click="goToTemplateUpcoming">
          未来活动
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout justify-center class="mb-2">
      <v-flex  class="d-flex justify-center">
        <v-btn
          class="primary"
          @click="createTemplate">
          <v-icon>add</v-icon>
          创建活动
        </v-btn>
      </v-flex>
    </v-layout>

    <div v-for="(lessonTemplate, lessonTemplateIndex) in lessonTemplatesShow" v-bind:key="`template-${lessonTemplateIndex}`">
      <y-template
        :lessonTemplate=lessonTemplate
        @templateDelete=removeTemplate(lessonTemplateIndex)
      />
    </div>

    <v-layout justify-center class="my-2">
      <v-btn
        text
        outlined
        color="grey"
        :loading="loading"
        :disabled="loading"
        @click="loadMore">
        加载更多
        <template v-slot:loader>
          <span class="custom-loader">
            <v-icon light>cached</v-icon>
          </span>
        </template>
      </v-btn>
    </v-layout>
    <!-- display overlay on the whole page while loading -->
    <v-overlay :value="$store.state.queryingState">
      <v-progress-circular
        indeterminate
        color=""
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</div>
</template>

<script>
import ProgramService from '@/services/ProgramService'
import LessonService from '@/services/LessonService'
import YTemplate from '@/components/YTemplate'

// import Vue from 'vue'
import { format, add } from 'date-fns'
import moment from 'moment'
import { LessonStatusLookup } from '../enums'
const config = require('../config/config')
// import consola from 'consola'
moment.locale('zh-cn')
// import util from '@/util'

export default {
  components: {
    YTemplate
  },
  data () {
    return {
      tutorId: null,
      organizationId: null,
      programs: [],
      programIds: [],
      lessonTemplates: [],
      lessonTemplatesShow: [],
      classes: [],
      loading: false,
      beforeTime: format(add(new Date(), { days: config.TEMPLATE_SHOW_DAYS_IN_ADVANCE }), 'yyyy-MM-dd HH:mm:ss')
    }
  },
  watch: {
    async $route (to, from) {
      if (this.$route.query.organizationId || this.$route.query.tutorId || this.$route.query.programId) { // 如果存在任何路径参数，则优先考虑
        this.$store.dispatch('setQueryingState', true)
        console.log('检测到路径参数变化')
        console.log(this.$route.query)
        this.resetData()
        if (this.$route.query.organizationId || this.$route.query.tutorId) {
          if (this.$route.query.organizationId) this.organizationId = this.$route.query.organizationId
          if (this.$route.query.tutorId) this.tutorId = this.$route.query.tutorId
          console.log('请求路径参数读取')
        }
        // get lessonTemplates by tutor Id (tutor can only see self-managed templates)
        this.lessonTemplates = await this.getFullLessonTemplatesInfo(6)

        // set classesShow for display
        this.lessonTemplatesShow = this.lessonTemplates

        // get classes by programId
        console.log(this.programIds)
        const resClasses = await ProgramService.getClasses({ programIds: this.programIds, statusCodes: [1], organizationIds: this.organizationIds }) // status_code: 1 -> active
        this.classes = resClasses.data
        console.log('classes retrived! classes: ', this.classes)
        // set queryingState
        this.$store.dispatch('setQueryingState', false)
      }
    }
  },
  async mounted () {
    // set queryingState
    this.$store.dispatch('setQueryingState', true)

    // get programs by organization Id
    if (this.$route.query.organizationId || this.$route.query.tutorId) {
      if (this.$route.query.organizationId) this.organizationId = this.$route.query.organizationId
      if (this.$route.query.tutorId) this.tutorId = this.$route.query.tutorId
      console.log('请求路径参数读取')
    } else if (this.$store.state.account.organizations || this.$store.state.account.tutor.id) { // 按当前用户的实际情况请求
      this.tutorId = this.$store.state.account.tutor.id
      const organizationIds = this.$store.state.account.organizations.map(organization => organization.id)
      this.organizationId = organizationIds[0]
      console.log('从用户身份读取')
    } else {
      console.log('该角色不具有机构或私教账号！')
    }

    // console.log('Fetch tutorId: ', this.tutorId)
    // console.log('Fetch organizationIds: ', this.organizationIds)
    // const res = await ProgramService.getPrograms({ organizationIds: this.organizationIds, statusCodes: [9, 0], attatchPaidedStudentsInfo: 0, attatchTags: 0, attatchPricePackages: 0, attatchMedia: 0 }) // 9: published 0: closed;
    // console.log('Fetch programs: ', res.data)
    // this.programs = res.data

    // Initialize programIds to include all programs under the organization
    // this.programs.forEach((program, index) => {
    //   if (program.status_code > 0) {
    //     this.programIds.push(program.program_id)
    //   }
    // })
    // console.log('programIds: ', this.programIds)

    // get lessonTemplates by tutor Id (tutor can only see self-managed templates)
    this.lessonTemplates = await this.getFullLessonTemplatesInfo(6)

    // set classesShow for display
    this.lessonTemplatesShow = this.lessonTemplates

    // get classes by programId
    console.log(this.programIds)
    const resClasses = await ProgramService.getClasses({ programIds: this.programIds, statusCodes: [1], organizationIds: this.organizationIds }) // status_code: 1 -> active
    this.classes = resClasses.data
    console.log('classes retrived! classes: ', this.classes)
    // set queryingState
    this.$store.dispatch('setQueryingState', false)
  },
  methods: {
    resetData () {
      this.tutorId = null
      this.organizationId = null
      this.programs = []
      this.programIds = []
      this.lessonTemplates = []
      this.lessonTemplatesShow = []
      this.classes = []
      this.loading = false
      this.beforeTime = format(add(new Date(), { days: config.TEMPLATE_SHOW_DAYS_IN_ADVANCE }), 'yyyy-MM-dd HH:mm:ss')
    },
    async loadMore () {
      console.log('load more lesson templates!')
      const newLessonTemplates = await this.getFullLessonTemplatesInfo(3)
      this.lessonTemplates.push(...newLessonTemplates)
    },
    async getFullLessonTemplatesInfo (limit) {
      this.loading = true
      // get lesson Templates
      const resLessonTemplateInfo = await LessonService.getLessonTemplates({
        'templateTutorIds': this.tutorId ? [this.tutorId] : null,
        'programIds': this.programIds,
        'organizationIds': this.organizationId ? [this.organizationId] : null,
        'beforeTime': this.beforeTime,
        'offset': this.lessonTemplates.length,
        'limit': limit
      })
      let lessonTemplateInfo = resLessonTemplateInfo.data
      // get session info for each lesson Template
      let lessonTemplateIds = []
      lessonTemplateInfo.forEach((lessonTemplate) => {
        lessonTemplateIds.push(lessonTemplate.template_id)
      })
      const resLessonSessions = await LessonService.getLessonSessions({ lessonTemplateIds })
      const lessonSessions = resLessonSessions.data
      // get enrollment info for each lesson session
      let lessonIds = []
      lessonSessions.forEach((lessonSession) => {
        lessonIds.push(lessonSession.id)
      })
      const resEnrollments = await LessonService.getLessonEnrollments({ lessonIds })
      const enrollments = resEnrollments.data
      // attach enrollment info to each lesson session
      lessonSessions.forEach((lessonSession) => {
        let lessonEnrollmentsForThisSession = enrollments.filter((enrollment) => {
          return lessonSession.id === enrollment.lesson_id
        })
        lessonSession.lessonEnrollmentInfo = lessonEnrollmentsForThisSession
        let lastestUnconfirmedLessonEnrollmentTimestampUpdate = Math.max.apply(Math, lessonSession.lessonEnrollmentInfo.map(function (item) { return item.confirmation_code === 1 ? null : new Date(item.lesson_enrollment_timestamp_update) }))
        lessonSession.lastestAutoConfirmTime = lastestUnconfirmedLessonEnrollmentTimestampUpdate === 0 ? null : moment(lastestUnconfirmedLessonEnrollmentTimestampUpdate + lessonTemplateInfo[0].lesson_enrollment_system_auto_confirm_ms).format('MM/DD HH:mm')
        lessonSession.show = false
      })
      // attach lesson sessions to each template
      lessonTemplateInfo.forEach((lessonTemplate) => {
        let lessonSessionsForThisTemplate = lessonSessions.filter((lessonSession) => {
          return lessonSession.lesson_template_id === lessonTemplate.template_id
        })
        lessonTemplate.lessonSessions = lessonSessionsForThisTemplate

        // calculate and inject lessonTemplateOverallStatusCode (see definition below)
        let lessonTemplateOverallStatusCode = 0 // 0: scheduled without any session, 1: session waiting for finalized, 2: finalized
        if (lessonSessionsForThisTemplate.length > 0) {
          lessonTemplateOverallStatusCode = LessonStatusLookup.INSTRUCTED
          let allSessionFinalized = true // flag
          lessonSessionsForThisTemplate.forEach((lessonSession) => {
            if (lessonSession.status_code !== 2) allSessionFinalized = false // if any one session is not finallized, set to false
          })
          if (allSessionFinalized) lessonTemplateOverallStatusCode = 2 // if all have been finalized, set overall statusCode to 2
        }
        lessonTemplate.lessonTemplateOverallStatusCode = lessonTemplateOverallStatusCode

        // 根据lessonTemplateOverallStatusCode 来决定初始状态是否将此课展开
        if (lessonTemplate.lessonTemplateOverallStatusCode === 1) {
          lessonTemplate.show = true
        } else {
          lessonTemplate.show = false
        }
      })
      // loading status => false
      this.loading = false
      // get overall status
      return lessonTemplateInfo
    },
    // async deleteLessonTemplate (lessonTemplateId) {
    //   console.log('deleteLessonTemplate starts ... lessonTemplateId: ', lessonTemplateId)
    //   const res = await LessonService.deleteLessonTemplate(lessonTemplateId)
    //   console.log('res: ', res)
    //   console.log('res.data.lessonTemplate.id: ', res.data.lessonTemplate.id, '; lessonTemplateId: ', lessonTemplateId)
    //   if (res.data.lessonTemplate.id === lessonTemplateId) { // lessontemplate deleted, async the array
    //     console.log('lessonTemplate is deleted! id: ', res.data.lessonTemplate.id)
    //     const self = this
    //     this.lessonTemplates.map(function (lessonTemplate, index) {
    //       console.log('index: ', index, '; lessonTemplate.id: ', lessonTemplate.id)
    //       if (lessonTemplate.template_id === lessonTemplateId) {
    //         console.log('found index: ', index, 'sync the lessonTemplates array...')
    //         self.lessonTemplates.splice(index, 1)
    //       }
    //     })
    //   }
    // },
    async updateTemplates () {
      console.log('Input event triggered! programeIds: ', this.programIds)

      // set queryingState
      this.$store.dispatch('setQueryingState', true)

      // update lessonTemplatesShow
      console.log('Begin updating the lessonTemplatesShow ...')
      var self = this
      this.lessonTemplatesShow = this.lessonTemplates.filter(function (singleTemplate) {
        return self.programIds.indexOf(singleTemplate.program_id) >= 0
      })
      console.log('this.lessonTemplatesShow: ', this.lessonTemplatesShow)

      // set queryingState
      this.$store.dispatch('setQueryingState', false)
    },
    // async studentAttendanceStatusFeedback (lessonEnrollmentId, confirmationCode) {
    //   console.log('Student feedback initiated (lessonEnrollmentId:', lessonEnrollmentId, '): code:', confirmationCode)
    //   await LessonService.studentAttendanceStatusFeedback(lessonEnrollmentId, confirmationCode)
    //   console.log('Successful!')
    // },
    async lessonEnrollment (programId, lessonTemplateId, lessonTemplateIndex) {
      // prepare classes
      const programClasses = this.classes.filter(function (el) {
        return el.program_id === programId
      })
      console.log('classes under current program are filtered to be: ', programClasses)

      // route
      this.$router.push({
        name: 'lesson-enrollment',
        params: {
          programId: programId,
          lessonTemplateId: lessonTemplateId,
          classes: programClasses
        }
      })
    },
    removeTemplate (index) {
      this.lessonTemplates.splice(index, 1)
    },
    goToTemplateUpcoming () {
      this.$router.push({
        name: 'lesson-templates-upcoming',
        query: this.$route.query ? this.$route.query : {}
      })
    },
    createTemplate () {
      this.$router.push({
        name: 'edit-lesson-template',
        query: this.$route.query ? this.$route.query : {}
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .lesson-template.template-overall-status-code-2 {
    border-left: 4px solid #3cd1c3;
  }
  .lesson-template.template-overall-status-code-1 {
    border-left: 4px solid orange;
  }
  .lesson-template.template-overall-status-code-0 {
    border-left: 4px solid grey;
  }
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
